import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { InfraPanelSidebarData } from "../../Module/infraPanelSidebarData";
import Chat from "../TeacherPanel/chat";
import { useSelector } from "react-redux";

const CreateIssue = React.lazy(() => import("../InfraPanel/createIssue"));
const EditDeleteIssue = React.lazy(() =>
  import("../InfraPanel/editDeleteIssue")
);
const InfraIssue = React.lazy(() => import("../InfraPanel/infraIssue"));
const EmployeeAsset = React.lazy(() => import("./employeeAssets"));
const CreateEmployeeAsset = lazy(() =>
  import("./EmployeeAssets/createEmployeeAsset")
);
const ListEmployeeAsset = lazy(() =>
  import("./EmployeeAssets/listEmployeeAsset")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));
function InfraPanelDashboard() {
  const {loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,

  }));
  console.log("loginType",loginType);
  return (
    <div className="AppBox">
      <Sidebar data={InfraPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CreateIssue />} />
              <Route path="createIssue" element={<CreateIssue />} />
              <Route path="editDeleteIssue" element={<EditDeleteIssue />} />
              <Route path="infraIssue" element={<InfraIssue />} />
              <Route path="/Chat" element={<Chat />} />
              <Route path="/employeeAsset" element={<EmployeeAsset />} />
              <Route
                path="/createEmployeeAsset"
                element={<CreateEmployeeAsset />}
              />
              <Route
                path="/listEmployeeAsset"
                element={<ListEmployeeAsset />}
              />
                <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
                <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default InfraPanelDashboard;
