export const CloSidebarData = [
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "ADMISSION - EDUTECH",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "batchWiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "dateWiseCount",
        cName: "sub-nav",
      },
      {
        title: "REVENUE DETAILS",
        path: "revenueDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtSolveReview",
  },
  {
    title: "ENROLLED EDUTECH",
    link: "enrolledEdutech",
  },
  {
    title: "ENROLLED EDUTECH STUDYMATERIAL",
    link: "enrolledStudyMaterial",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationDetails",
  },
  {
    title: "PACKAGE DETAILS",
    subNav: [
      {
        title: "EXTRA/OLD PACKAGE EXISTS",
        path: "oldPackageExist",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DUPLICATE URL",
    link: "youtubeDuplicateUrl",
  },
  {
    title: "EDUTECH COUPON CODE",
    subNav: [
      {
        title: "CREATE",
        path: "createCouponCode",
        cName: "sub-nav",
      },
      {
        title: "CREATE MULTIPLE EXAM",
        path: "createCouponCodeMultipleExam",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCouponCode",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH COUNSELLOR EXAM COUPON",
    subNav: [
      {
        title: "CREATE ALL COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeExam",
        cName: "sub-nav",
      },
      {
        title: "CREATE SINGLE COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCouponCodeEdutech",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH ALL COURSES COUNSELLOR COUPON & EXTENSION",
    subNav: [
      {
        title: "CREATE",
        path: "createCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "COPY COUNSELLOR COUPON",
        path: "copyCounsellorCoupon",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BASE OFFER DISCOUNT",
    subNav: [
      {
        title: "ADD",
        path: "baseOfferDiscount",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editBaseOfferDiscount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXTEND GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createExtendGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteExtendGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPGRADE GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createUpgradeGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteUpgradeGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WHATSAPP MESSAGE",
    subNav: [
      {
        title: "ADD REGISTRATION CONFIGURATION",
        path: "studentCouponAdd",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "studentCouponEdit",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PACKAGE",
    subNav: [
      {
        title: "CREATE",
        path: "createPackage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePackage",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DISPATCH CHARGES",
    subNav: [
      {
        title: "CREATE",
        path: "addDispatchCharges",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDispatchCharges",
        cName: "sub-nav",
      },
    ],
  },
];

export const CloEmpSidebarData = [
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "ADMISSION - EDUTECH",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "batchWiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "dateWiseCount",
        cName: "sub-nav",
      },
      {
        title: "REVENUE DETAILS",
        path: "revenueDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtSolveReview",
  },
  {
    title: "ENROLLED EDUTECH",
    link: "enrolledEdutech",
  },
  {
    title: "ENROLLED EDUTECH STUDYMATERIAL",
    link: "enrolledStudyMaterial",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationDetails",
  },
  {
    title: "PACKAGE DETAILS",
    subNav: [
      {
        title: "EXTRA/OLD PACKAGE EXISTS",
        path: "oldPackageExist",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DUPLICATE URL",
    link: "youtubeDuplicateUrl",
  },
  {
    title: "EDUTECH COUPON CODE",
    subNav: [
      {
        title: "CREATE",
        path: "createCouponCode",
        cName: "sub-nav",
      },
      {
        title: "CREATE MULTIPLE EXAM",
        path: "createCouponCodeMultipleExam",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCouponCode",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH COUNSELLOR EXAM COUPON",
    subNav: [
      {
        title: "CREATE ALL COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeExam",
        cName: "sub-nav",
      },
      {
        title: "CREATE SINGLE COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCouponCodeEdutech",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH ALL COURSES COUNSELLOR COUPON & EXTENSION",
    subNav: [
      {
        title: "CREATE",
        path: "createCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "COPY COUNSELLOR COUPON",
        path: "copyCounsellorCoupon",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BASE OFFER DISCOUNT",
    subNav: [
      {
        title: "ADD",
        path: "baseOfferDiscount",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editBaseOfferDiscount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXTEND GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createExtendGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteExtendGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPGRADE GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createUpgradeGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteUpgradeGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WHATSAPP MESSAGE",
    subNav: [
      {
        title: "ADD REGISTRATION CONFIGURATION",
        path: "studentCouponAdd",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "studentCouponEdit",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PACKAGE",
    subNav: [
      {
        title: "CREATE",
        path: "createPackage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePackage",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DISPATCH CHARGES",
    subNav: [
      {
        title: "CREATE",
        path: "addDispatchCharges",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDispatchCharges",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
