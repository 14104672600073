import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router-dom";
import {
  InfluencerEmpPanelSidebarData,
  InfluencerPanelSidebarData,
} from "../../Module/influencerPanelSidebarData";
import { useSelector } from "react-redux";

const Dashboard = lazy(() => import("./dashboard"));
const RevenueAnalysis = lazy(() => import("./revenueAnalysis"));
const TotalRevenue = lazy(() => import("./totalRevenue"));
const LeadOrder = lazy(() => import("./leadOrder"));
const InfluencerRank = lazy(() => import("./influencerRank"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const Guidelines = lazy(() => import("./guidelines"));
const Agreement = lazy(() => import("./agreement"));

const InfluencerDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);

  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2
            ? InfluencerEmpPanelSidebarData
            : InfluencerPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="revenueAnalysis" element={<RevenueAnalysis />} />
              <Route path="totalRevenue" element={<TotalRevenue />} />
              <Route path="leadOrder" element={<LeadOrder />} />
              <Route path="influencerRank" element={<InfluencerRank />} />
              <Route
                path="movementsOfMeeting"
                element={<MovementsOfMeeting />}
              />
              <Route
                path="editMovementsOfMeeting"
                element={<EditMovementsOfMeeting />}
              />
              <Route path="guidelines" element={<Guidelines />} />
              <Route path="agreement" element={<Agreement />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default InfluencerDashboard;
