export const CounsellorSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];


export const CounsellorEmpSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
