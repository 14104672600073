export const SalesUpsellingPanelSidebarData = [
  {
    title: "TRANSACTION EDUTECH",
    link: "salestransactionedutech", 
  },
  {
    title: "TRANSACTION PUBLICATION",
    link: "transactionpublication",
  },
  {
    title: "EXAM RESULTS",
    link: "examresults",
  },
  {
    title: "SECOND INSTALLMENT",
    link: "secondinstallment",
  },
];

export const SalesUpsellingEmpPanelSidebarData = [
  {
    title: "TRANSACTION EDUTECH",
    link: "salestransactionedutech", 
  },
  {
    title: "TRANSACTION PUBLICATION",
    link: "transactionpublication",
  },
  {
    title: "EXAM RESULTS",
    link: "examresults",
  },
  {
    title: "SECOND INSTALLMENT",
    link: "secondinstallment",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
