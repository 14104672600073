import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../Module/pageTitle";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  getAllTeachersList,
  getExamListNew,
  getParentCourseNew,
  getYTAnalytics,
} from "../../Action/courseAction";
import AdminTable from "../../Component/adminTable";
import Dropdown from "../../Component/dropdown";
import SubmitButton from "../../Component/SubmitButton";

const TeacherPerformance = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [minDate, setMinDate] = useState("");
  const [startDate, setStartDate] = useState(
    moment.utc(moment.utc(new Date())).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(moment.utc(new Date())).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [exam, setExam] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [examStream, setExamStream] = useState("");

  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [teacherPerformList, setTeacherPerformList] = useState([]);

  useEffect(() => {
    setMinDate(moment.utc(new Date()).format("YYYY-MM-DD"));
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getAllTeachersList(
        {
          skip: 0,
          limit: 100,
        },
        (res) => {
          setTeacherList(res?.data?.data);
        },
        () => {}
      )
    );
    displayYTAnalytics();
  }, []);

  const onChangeData = (value, key) => {
    if (key === "startDate") {
      setStartDate(value);
    } else if (key === "endDate") {
      setEndDate(value);
      setExamStream("");
      setTeacherName("");
      setTeacherPerformList([]);
      if (value < startDate) {
        dispatch(showErrorSnackbar("End date must be greater than start date"));
      }
    } else if (key === "exam") {
      setExam(value);
      setExamStream("");
      setExamStreamList([]);
      setTeacherName("");
      setTeacherPerformList([]);
      if (value) {
        dispatch(
          getParentCourseNew(`${value._id}`, (res) => {
            setExamStreamList(res);
          })
        );
      }
    } else if (key === "examStream") {
      setExamStream(value);
      setTeacherName("");
      setTeacherPerformList([]);
    } else if (key === "teacherName") {
      setTeacherName(value);
    }
  };

  const validations = () => {
    return false;
  };

  const renderSearchMode = () => {
    let maxD = moment(startDate).add(1, "months").calendar();
    return (
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-wrap">
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <div className="col-md-4">
              <LabelField labelName={"Start Date"} isRequired={true} />
            </div>
            <div className="col-md-8">
              <DatePicker
                setInputText={(value) => onChangeData(value, "startDate")}
                value={startDate}
                maxdate={minDate}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <div className="col-md-4">
              <LabelField labelName={"End Date"} isRequired={true} />
            </div>
            <div className="col-md-8">
              <DatePicker
                setInputText={(value) => onChangeData(value, "endDate")}
                value={endDate}
                mindate={startDate}
                maxdate={moment(maxD).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <div className="col-md-4">
              <LabelField labelName={"Exam Name"} />
            </div>
            <div className="col-md-8">
              <Dropdown
                labelName="Select Exam Name"
                setInputText={(value) => onChangeData(value, "exam")}
                options={examList}
                value={exam}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <div className="col-md-4">
              <LabelField labelName={"Exam Stream"} />
            </div>
            <div className="col-md-8">
              <Dropdown
                labelName="Select Exam Stream"
                setInputText={(value) => onChangeData(value, "examStream")}
                options={examStreamList}
                value={examStream}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <div className="col-md-4">
              <LabelField labelName={"Teacher Name"} />
            </div>
            <div className="col-md-8">
              <Dropdown
                labelName="Select Teacher Name"
                setInputText={(value) => onChangeData(value, "teacherName")}
                options={teacherList}
                value={teacherName}
              />
            </div>
          </div>
          <SubmitButton name="Search" validations={validations()} />
          {teacherPerformList?.length >= 0 ? (
            <div className="d-flex  mb-2 align-items-center">
              <DownloadTableExcel
                filename="Teacher Task List"
                sheet="Teacher Task List"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-outline-primary ms-1 rounded-pill">
                  Export Details
                </button>
              </DownloadTableExcel>
            </div>
          ) : null}
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <AdminTable
          pagename={"teacherPerformanceCxo"}
          tableHead={[
            "S.N",
            "DATE",
            "LECTURE TYPE",
            "EXAM",
            "DEPARTMENT",
            "TEACHER NAME",
            "YT LINK",
            "DURATION",
            "VIEWS",
            "LIKE",
            "COMMENT",
            "FAVORITE",
          ]}
          tableData={teacherPerformList ? teacherPerformList : []}
        />
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    displayYTAnalytics();
  };

  const displayYTAnalytics = () => {
    let data = {
      startDate,
      endDate,
    };
    if (exam) {
      data.examTypeId = exam?._id;
    }
    if (examStream) {
      data.baseCourseId = examStream?._id;
    }
    if (teacherName) {
      data.teacherId = teacherName?._id;
    }
    dispatch(
      getYTAnalytics(data, (resp) => {
        let filter_data = resp?.data?.filter((item) => {
          return item?.taskType === "YOUTUBE_LIVE";
        });
        setTeacherPerformList(filter_data);
      })
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"TEACHER PERFORMANCE"} />
      <div className="formDiv contentScrollbar">
        <div className="col-12 formWidth">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
};

export default TeacherPerformance;
