import React, { useEffect, useState } from "react";
import PageTitle from "../../../Module/pageTitle";
import LabelField from "../../../Component/labelField";
import FormInputField from "../../../Component/FormInputField";
import DatePicker from "../../../Component/datePicker";
import SubmitButton from "../../../Component/SubmitButton";
import {
  addUpcomingBatch,
  getExamListNew,
  getParentCourseNew,
} from "../../../Action/courseAction";
import { useDispatch } from "react-redux";
import Dropdown from "../../../Component/dropdown";
import {
  containsNumber,
  validateValueWithoutSpace,
} from "../../../Utils/validators";
import { showErrorSnackbar } from "../../../Action/snackbarAction";

function CreateUpcomingBatches({ length_Fifty, length_One, length_Two }) {
  const dispatch = useDispatch();

  const [addName, setAddName] = useState([]);
  const [examName, SetExamName] = useState("");
  const [addStrem, setAddStrem] = useState([]);
  const [examStream, setExamStream] = useState("");
  const [addType, setAddType] = useState("");
  const [batchName, setBatchName] = useState("");
  const [batchTrgete, setBatchTrgete] = useState(100);
  const [datePickers, setDatePickers] = useState("");
  const [batchId, setBatchId] = useState("");

  const typeData = [
    {
      _id: 1,
      name: "ONLINE COURSE BY VIP",
    },
    {
      _id: 6,
      name: "CRASH COURSE BY VIP",
    },
  ];

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setAddName(res);
      })
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postdata = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      upcomingBatchName: batchName,
      type: addType._id,
      admissionTarget: parseInt(batchTrgete),
      batchDate: datePickers,
      courseId: batchId,
    };
    dispatch(
      addUpcomingBatch(postdata, (res) => {
        if (res) {
          setAddName("");
          setAddStrem("");
          setAddType("");
          setBatchName("");
          setDatePickers("");
          setBatchId("");
        }
      })
    );
  };
  const validations = () => {
    return (
      examStream === "" ||
      examName === "" ||
      batchName.length < 3 ||
      addType === "" ||
      batchTrgete === "" ||
      datePickers === ""
    );
  };

  const onchangeExamName = (value) => {
    SetExamName(value);
    dispatch(
      getParentCourseNew(`${value._id}`, (res) => {
        setAddStrem(res);
      })
    );
  };
  const onChangeExamNameOrder = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else setBatchTrgete(value);
    }
    setBatchTrgete(value);
  };

  return (
    <div>
      <div className="contentBox">
        <PageTitle name={"UPCOMING BATCHES"} />
        <div className="formDiv contentScrollbar">
          <form className="formWidth" onSubmit={handleSubmit}>
            <div className="d-flex mb-2 align-items-center w-100">
              <LabelField labelName={"Exam Name"} isRequired={true} />
              <Dropdown
                labelName={"Exam Name"}
                setInputText={(value) => onchangeExamName(value)}
                options={addName}
                defaultValue={examName}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Stream"} isRequired={true} />
              <Dropdown
                labelName={"Exam Stream"}
                setInputText={(value) => setExamStream(value)}
                options={addStrem}
                defaultValue={examStream}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Type"} />
              <Dropdown
                labelName={"Type"}
                setInputText={(value) => setAddType(value)}
                options={typeData}
                defaultValue={addType._id}
              />
            </div>
            <div className="d-flex align-items-center">
              <LabelField labelName={"Batch Name"} isRequired={true} />
              <FormInputField
                key={"ex2"}
                placeholder={"Enter Batch Name"}
                setInputText={(value) => setBatchName(value)}
                value={batchName}
                maxLength={length_Fifty}
                minLength={length_Two}
              />
            </div>
            <div className="d-flex align-items-center">
              <LabelField labelName={"Batch ID"} />
              <FormInputField
                key={"ex2"}
                placeholder={"Enter Batch ID"}
                setInputText={(value) => setBatchId(value)}
                value={batchId}
                maxLength={length_Fifty}
                minLength={length_Two}
              />
            </div>
            <div className="d-flex align-items-center">
              <LabelField labelName={"Admission Target"} isRequired={true} />
              <FormInputField
                key={"ex2"}
                placeholder={"Enter Target"}
                setInputText={(value) => onChangeExamNameOrder(value)}
                value={batchTrgete}
                name="batchTrgete"
                maxLength={length_Fifty}
                minLength={length_One}
              />
            </div>
            <div className="d-flex mb-2 align-items-center  ">
              <LabelField labelName={"Batch Date :"} isRequired={true} />
              <DatePicker
                setInputText={(value) => {
                  setDatePickers(value);
                }}
                value={datePickers}
              />
            </div>
            <SubmitButton name="submit" validations={validations()} />
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUpcomingBatches;
