import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { SMTEmpSideBarData, SMTSideBarData } from "../../Module/SMTeamPanel/smtSidebarData";
import ResultCompilation from "../../Module/SMTeamPanel/resultCompilation";
import { useSelector } from "react-redux";

const AddYoutubeLive = React.lazy(() =>
  import("../CategoryPanel/addYoutubeLive")
);
const EditDeleteYoutubeLive = React.lazy(() =>
  import("../CategoryPanel/editDeleteYoutubeLive")
);

const AddYoutubeRecordedVideo = React.lazy(() =>
  import("../CategoryPanel/addYoutubeRecordedVideo")
);
const EditDeleteYoutubeRecordedVideo = React.lazy(() =>
  import("../CategoryPanel/editDeleteYoutubeRecordedVideo")
);
const AddFreeUserVideo = React.lazy(() =>
  import("../DataEntryPanel/addFreeUserVideo")
);
const EditDeleteFreeUserVideo = React.lazy(() =>
  import("../DataEntryPanel/editDeleteFreeUserVideo")
);
const YoutubeSession = React.lazy(() => import("./youtubeSession"));
//EXAM SOURCE VIDEO
const AddExamSrcVideo = React.lazy(() =>
  import("./ExamSourceVideo/createExamSourceVideo")
);
const EditExamSrcVideo = React.lazy(() =>
  import("./ExamSourceVideo/editExamSrcVideo")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));
const SMTDashboard = () => {
  const {loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,

  }));
  console.log("loginType",loginType);
  
  return (
    <div className="AppBox">
      <Sidebar data={loginType === 2 ?SMTEmpSideBarData:SMTSideBarData} />
      <div className="dashboard">
        <Suspense
          fallback={
            <div className="border p-1">
              <Loading />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<AddYoutubeLive />} />
            <Route path="addYoutubeLive" element={<AddYoutubeLive />} />
            <Route
              path="editDeleteYoutubeLive"
              element={<EditDeleteYoutubeLive />}
            />
            <Route
              path="addYoutubeRecordedVideo"
              element={<AddYoutubeRecordedVideo />}
            />
            <Route
              path="editDeleteYoutubeRecordedVideo"
              element={<EditDeleteYoutubeRecordedVideo />}
            />
            <Route path="addFreeVideo" element={<AddFreeUserVideo />} />
            <Route path="editFreeVideo" element={<EditDeleteFreeUserVideo />} />
            <Route path="youtubeSession" element={<YoutubeSession />} />
            <Route path="addExamSrcVideo" element={<AddExamSrcVideo />} />
            <Route path="editExamSrcVideo" element={<EditExamSrcVideo />} />
            <Route path="resultCompilation" element={<ResultCompilation />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default SMTDashboard;
