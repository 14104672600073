import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { ConfigDataSidebarData, ConfigEmpSidebarData } from "../../Module/configDataSidebarData";
import AddIssueType from "./addIssueType";
import EditDeleteIssueType from "./editDeleteIssueType";
import Referral from "./AdditionalDiscount/referral";
import Influencer from "./AdditionalDiscount/influencer";
import { useSelector } from "react-redux";

const AddBBBPDFandVideo = React.lazy(() => import("./addBBBPDFandVideo"));
const SupportNumber = React.lazy(() => import("./supportNumber"));
const SupportEmail = React.lazy(() => import("./supportEmail"));
const AdminEmail = React.lazy(() => import("./adminEmail"));
const Student = React.lazy(() => import("./AdditionalDiscount/student"));
const Pamplet = React.lazy(() => import("./AdditionalDiscount/pamplet"));
const InvoiceReceivedEmail = React.lazy(() => import("./invoiceReceivedEmail"));
const LiveVideoSetting = React.lazy(() => import("./liveVideoSetting"));
const TaxSetting = React.lazy(() => import("./taxSetting"));
const InstallmentSetting = React.lazy(() => import("./installmentSetting"));
const AddCharges = React.lazy(() => import("./addCharges"));
const EditDeleteCharges = React.lazy(() => import("./editDeleteCharges"));
const AppVersionControl = React.lazy(() => import("./appVersionControl"));
const OtpSetting = React.lazy(() => import("./otpSetting"));
const ServersList = React.lazy(() => import("./serversList"));
const AddQuestionConfig = React.lazy(() => import("./addQuestionConfig"));
const EditDeleteQuestionConfig = React.lazy(() =>
  import("./editDeleteQuestionConfig")
);
const AddQuestionIssue = React.lazy(() =>
  import("./QuestionIssue/addQuestionIssue")
);
const EditQuestionIssue = React.lazy(() =>
  import("./QuestionIssue/editQuestionIssue")
);
const WhatsappMsg = React.lazy(() => import("./whatsappMsg"));
const LeadOrdersSearch = React.lazy(() => import("./leadOrdersSearch"));
const WhatsappShootData = React.lazy(() =>
  import("../CXOPanel/whatsappShootData")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));

function ConfigDataDashboard() {
  const {loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,

  }));
  console.log("loginType",loginType);
  return (
    <div className="AppBox">
      <Sidebar data={loginType===2?ConfigEmpSidebarData:ConfigDataSidebarData} />
      <div className="dashboard">
        <Suspense
          fallback={
            <div className="border p-1">
              <Loading />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<SupportNumber />} />
            <Route path="supportNumber" element={<SupportNumber />} />
            <Route path="supportEmail" element={<SupportEmail />} />
            <Route path="adminEmail" element={<AdminEmail />} />
            <Route path="student" element={<Student />} />
            <Route path="pamplet" element={<Pamplet/>}/>
            <Route path="influencer" element={<Influencer/>}/>
            <Route path="referral" element={<Referral/>}/>
            <Route
              path="invoiceReceivedEmail"
              element={<InvoiceReceivedEmail />}
            />
            <Route path="liveVideoSetting" element={<LiveVideoSetting />} />
            <Route path="taxSetting" element={<TaxSetting />} />
            <Route path="installmentSetting" element={<InstallmentSetting />} />
            <Route path="addIssueType" element={<AddIssueType />} />
            <Route
              path="editDeleteIssueType"
              element={<EditDeleteIssueType />}
            />
            <Route path="addQuestionConfig" element={<AddQuestionConfig />} />
            <Route
              path="editDeleteQuestionConfig"
              element={<EditDeleteQuestionConfig />}
            />
            <Route path="addCharges" element={<AddCharges />} />
            <Route path="editDeleteCharges" element={<EditDeleteCharges />} />
            <Route path="appVersionControl" element={<AppVersionControl />} />
            <Route path="otpSetting" element={<OtpSetting />} />
            <Route path="serversList" element={<ServersList />} />
            <Route path="addQuestionIssue" element={<AddQuestionIssue />} />
            <Route path="editQuestionIssue" element={<EditQuestionIssue />} />
            <Route path="whatsappMsg" element={<WhatsappMsg />} />
            <Route path="leadOrdersSearch" element={<LeadOrdersSearch />} />
            <Route path="whatsappShotData" element={<WhatsappShootData />} />
            <Route path="addBBBPDFandVideo" element={<AddBBBPDFandVideo />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default ConfigDataDashboard;
