import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { SalesUpsellingEmpPanelSidebarData, SalesUpsellingPanelSidebarData } from "../../Module/salesUpsellingPanelSidebarData";
import ScholarshipExamResult from "./scholarshipExamResult";
import Leads from "./leads";
import { useSelector } from "react-redux";

const SalesTransactionEdutech = React.lazy(() =>
  import("../SalesUpsellingPanel/salesTransactionEdutech")
);
const TransactionPublication = React.lazy(() =>
  import("../Finance/transactionPublication")
);
const ExamResults = React.lazy(() => import("./examResults"));
const RegisteredUser = React.lazy(() => import("../CXOPanel/registeredUser"));
const AssuredRevenueSales = React.lazy(() =>
  import("../Finance/assuredRevenueSales")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));

function SalesDashboard() {
  const {loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,

  }));
  console.log("loginType",loginType);
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={loginType===2?SalesUpsellingEmpPanelSidebarData:SalesUpsellingPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<SalesTransactionEdutech />} />
              <Route
                path="salesTransactionEdutech"
                element={<SalesTransactionEdutech />}
              />
              <Route
                path="transactionPublication"
                element={<TransactionPublication />}
              />
              <Route path="examResults" element={<ExamResults />} />
              <Route
                path="ScholarshipExamResult"
                element={<ScholarshipExamResult />}
              />
              <Route path="leads" element={<Leads />} />
              <Route path="registeredUser" element={<RegisteredUser />} />
              <Route
                path="secondinstallment"
                element={<AssuredRevenueSales />}
              />
              <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
              <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default SalesDashboard;
