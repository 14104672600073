export const upSellingPanelSidebarData = [
  {
    title: "UPCOMING EXPIRY",
    link: "upcomingexpiry",
  },
  {
    title: "EXPIRED ACCOUNT",
    link: "expiredaccount",
  },
];
export const upSellingEmpPanelSidebarData = [
  {
    title: "UPCOMING EXPIRY",
    link: "upcomingexpiry",
  },
  {
    title: "EXPIRED ACCOUNT",
    link: "expiredaccount",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
