export const ManagerSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];

export const ManagerEmpSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];

