import React, { useEffect, useState } from "react";
import "../../../App.css";
import PageTitle from "../../../Module/pageTitle";
import LabelField from "../../../Component/labelField";
import { useDispatch,useSelector } from "react-redux";
import { getDepartmentList, fetchDepartmentEmployees,addMeetingDetails } from "../../../Action/courseAction";
import SubmitButton from "../../../Component/SubmitButton";
import CustomDropdown from "../../../Component/customDropdown";
import InputField from "../../../Component/inputField";
import DatePicker from "../../../Component/datePicker";
import AddMorebtn from "../../../Component/addMoreBtn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import {taskStatus} from "../../../Utils/dataConstants"
// const [bannerTypeArr, setBannerTypeArr] = useState(initialVal);

import { initialVal } from "../../../Utils/dataConstants";
// let myDate = new Date();
 
 
function MovementsOfMeeting() {
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedPresentEmployees, setSelectedPresentEmployees] = useState([]);
  const [selectedAbsentsEmployees, setSelectedAbsentsEmployees] = useState([]);
  const [duration, setDuration] = useState("");
  const [agenda, setAgenda] = useState("");
  const [checkDepartment,setCheckDepartment]=useState(true);  
  const [department,setDepartment]=useState("")

  // const [meetingWith,setMeetingWith]=useState([]);
  // const [attendeeMembers,setattendeeMembers]=useState([]);
  // const [presentMembers,setpresentMembers]=useState([]);
  // const [absentMembers,setabsentMembers]=useState([]);

  const initialVal=[{
    title:"",
    assignedTo:"",
    status:"",
    remark:""
  }];
  const [taskDetailsArr,setTaskDetailsArr]=useState(initialVal)
 
  const { id,departmentId } = useSelector((state) => ({
    departmentId : state?.Auth?.departmentId,
    id: state.Auth?.userInfo?._id,
  }));
  console.log("departmentId",departmentId)
  const [ myDate, setDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(
      getDepartmentList((res) => {
        setDepartmentList(res);
        let filteredID = res?.filter(
          (item) => 
            item?._id === departmentId,
          setDepartment(filteredID[0]?._id)
        );
        setCheckDepartment(department)
      })
    );
  }, [dispatch]);
  console.log("department",checkDepartment)
 
  const changeCheckBox = (event, index) => {
    let updatedDepartmentList = [...departmentList];
    updatedDepartmentList[index].assigned = event.target.checked;
    setDepartmentList(updatedDepartmentList); // Update department list
 
    let updatedSelectedDepartments = [...selectedDepartments];
    if (event.target.checked) {
      updatedSelectedDepartments.push(updatedDepartmentList[index]._id);
    } else {
      updatedSelectedDepartments = updatedSelectedDepartments.filter(
        (id) => id !== updatedDepartmentList[index]._id
      );
    }
    setSelectedDepartments(updatedSelectedDepartments); // Store selected department list
    console.log("unchecked departments=",updatedSelectedDepartments);
    
  };

  const onPressAdd = () => {
    setTaskDetailsArr([...taskDetailsArr, initialVal[0]]);
  };

  const handleClose = (index) => {
    const newArray = [...taskDetailsArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setTaskDetailsArr(newArray);
  };
 
  const onChangeTaskDetails = (value,key,index) => {
    // setBannerTypeArr([...taskDetailsArr, initialVal[0]]);
    let newArray = JSON.parse(JSON.stringify(taskDetailsArr));
    // if (key === "followers") {
    //   if (validateIsNumberOnly(value)) { 
    //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    //   } else {
    //     newArray[index][key] = value;
    //   }
    //   updateState("platformArr", newArray);
    // } else {
    
      newArray[index][key] = value;
      setTaskDetailsArr(newArray);
    // }
    // updateState("platformArr", newArray);
  };


  const onChangeDate = (value) => {
    // if (value > endDate) {
    //   dispatch(showErrorSnackbar("start date must be smaller than end date"));
    //   return;
    // }
    setDate(value);
  };
 
  useEffect(() => {
    if (selectedDepartments.length >= 1) {
      callFetchDepartmentEmployees(selectedDepartments);
    }
  }, [selectedDepartments]);
 
  const callFetchDepartmentEmployees = (meetingWith1) => { // meetingWith is a parameter consisting of values in selectedDepartments
    const requestData = {
      meetingWith:meetingWith1,
    };
 
    dispatch(
      fetchDepartmentEmployees(requestData, (res) => {
        setEmployeeList(res);
      })
    );
  };
 
  const changeEmployeeCheckBox = (event, index) => {
    const updatedSelectedEmployees = [...selectedEmployees];
    const employeeId = employeeList[index]._id;
 
    if (event.target.checked) {
      updatedSelectedEmployees.push(employeeId);
    } else {
      const employeeIndex = updatedSelectedEmployees.indexOf(employeeId);
      if (employeeIndex > -1) {
        updatedSelectedEmployees.splice(employeeIndex, 1);
      }
    }
    setSelectedEmployees(updatedSelectedEmployees);
  };
 
  const presentEmployees = Array.isArray(employeeList) ?employeeList.filter(employee => !selectedEmployees.includes(employee._id)):[];
  const concateData=selectedEmployees.concat(selectedPresentEmployees)
  const absentEmployees = Array.isArray(employeeList) ?employeeList.filter(employee => !concateData.includes(employee._id)):[];
 
  const responsible=selectedEmployees.concat(selectedPresentEmployees,selectedAbsentsEmployees);
  const responsibleEmployees = Array.isArray(employeeList)
  ? employeeList.filter(employee => responsible.includes(employee._id))
  : [];

  console.log("all responsible-",responsible);
  console.log("all responsible Employees-",responsibleEmployees);
  
  // Function to toggle present employees
const togglePresentCheckBox = (event, employeeId) => {
  const updatedSelectedPresentEmployees = [...selectedPresentEmployees];
 
  if (event.target.checked) {
      // Employee is marked as present
      if (!updatedSelectedPresentEmployees.includes(employeeId)) {
          updatedSelectedPresentEmployees.push(employeeId);
      }
  } else {
      // Employee is unmarked as present, should be considered absent
      const employeeIndex = updatedSelectedPresentEmployees.indexOf(employeeId);
      if (employeeIndex > -1) {
          updatedSelectedPresentEmployees.splice(employeeIndex, 1);
      }
  }
 
  // Update present employees list
  setSelectedPresentEmployees(updatedSelectedPresentEmployees);
 
  // Calculate and update absent employees based on updated present employees
  const updatedAbsentEmployees = employeeList.filter(employee => !updatedSelectedPresentEmployees.includes(employee._id));
  //setSelectedAbsentsEmployees(updatedAbsentEmployees); // Update absent employees state
};
 
// Function to toggle absent employees
const toggleAbsentCheckBox = (event, employeeId) => {
  const updatedSelectedAbsentsEmployees = [...selectedAbsentsEmployees];
 
  if (event.target.checked) {
      // Add employee to absent employees list  
      if (!updatedSelectedAbsentsEmployees.includes(employeeId)) {
          updatedSelectedAbsentsEmployees.push(employeeId);
      }
  } else {
      // Remove employee from absent employees list
      const employeeIndex = updatedSelectedAbsentsEmployees.indexOf(employeeId);
      if (employeeIndex > -1) {
          updatedSelectedAbsentsEmployees.splice(employeeIndex, 1);
      }
  }
 
  // Update absent employees list
  setSelectedAbsentsEmployees(updatedSelectedAbsentsEmployees);
};


const onChangeDuration = (value) => {
  setDuration(value);
};

const onChangeAgenda = (value) => {
  setAgenda(value);
};
console.log("absent -",selectedAbsentsEmployees);

 
const handleSubmit = async (e) => {
  console.log("helloooo");  
  e.preventDefault();
  
  
  const postData = {
    meetingWith: selectedDepartments,
    attendeeMembers: selectedEmployees,
    presentMembers: selectedPresentEmployees,
    absentMembers: selectedAbsentsEmployees,
    hostId: id,
    date: myDate,
    duration: duration,
    agenda: agenda,
    tasks: taskDetailsArr,
  };
  dispatch(
    addMeetingDetails(
      postData,
      () => {
        setTaskDetailsArr(initialVal);
      },
      () => {}
    )
  );
};
console.log("responsible=",responsible);

 
  return (
    <div className="contentBox">
      <PageTitle name={"MOVEMENTS OF MEETING "} />
      <div className="formDiv">
      <form className="formWidth" onSubmit={handleSubmit}>
        {departmentList?.length > 0 && (
          <div className="d-flex Justify-content-between">
            <LabelField labelName={"Departments"} />
            <div className="d-flex col-8 ps-3">
              <div className="border rounded p-3">
                <div className="input-border">
                  {departmentList.map((item, index) => {
                    const { _id, name } = item;
                    return (
                      <div key={_id} className="d-flex center p-1">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                id={_id}
                                className="check"
                                type="checkbox" 
                                checked={item?.assigned || false}
                                onChange={(event) => changeCheckBox(event, index)}
                                defaultChecked={checkDepartment ?"checked":undefined}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
 
        {employeeList.length > 0 && (
          <div className="d-flex Justify-content-between mt-4">
            <LabelField labelName={"Attendee Details"} />
            <div className="d-flex col-8 ps-3">
              <div className="border rounded p-3">
                <div className="input-border">
                  {employeeList.map((employee, index) => {
                    const { _id, name } = employee;
                    return (
                      <div key={_id} className="d-flex center p-1">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                id={_id}
                                className="check"
                                type="checkbox"
                                checked={selectedEmployees.includes(_id)}
                                onChange={(event) => changeEmployeeCheckBox(event, index)}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
 
        {/* Present Details with Checkboxes */}
        {presentEmployees.length > 0 && (
          <div className="d-flex Justify-content-between mt-4">
            <LabelField labelName={"Present Details"} />
            <div className="d-flex col-8 ps-3">
              <div className="border rounded p-3">
                <div className="input-border">
                  {presentEmployees.map((employee) => {
                    const { _id, name } = employee;
                    return (
                      <div key={_id} className="d-flex center p-1">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                type="checkbox"
                                checked={selectedPresentEmployees.includes(_id)}
                                onChange={(event) => togglePresentCheckBox(event, _id)}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
 
        {/* Absent Details with Checkboxes */}
        {absentEmployees.length > 0 && (
          <div className="d-flex Justify-content-between mt-4">
            <LabelField labelName={"Absent Details"} />
            <div className="d-flex col-8 ps-3">
              <div className="border rounded p-3">
                <div className="input-border">
                  {absentEmployees.map((employee) => {
                    const { _id, name } = employee;
                    return (
                      <div key={_id} className="d-flex center p-1">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                type="checkbox"
                                checked={selectedAbsentsEmployees.includes(_id)}
                                onChange={(event) => toggleAbsentCheckBox(event, _id)}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
 
 <div className="col d-flex justify-content-center align-items-center pt-4 px-3">
                <LabelField labelName={"Date"} isRequired={true} />
                <DatePicker
                  setInputText={(value) => onChangeDate(value)}
                  value={myDate}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  pickerStyle={{ width: "100%" }}
                />    
              </div>
              
 
        <div className="px-3">
          <InputField
            labelName={"Duration"}
            placeholder="Enter Duration"
            setInputText={(value) => onChangeDuration(value)}
            value={duration}
          />
          </div>
          <div className="px-3">
          <InputField
            labelName={"Meeting Agenda"}
            placeholder="Enter Meeting Agenda"
            setInputText={(value) => onChangeAgenda(value)}
            value={agenda}
          />
          </div>

          
          <LabelField labelName={"Task Details"}/>

          {taskDetailsArr.map((item, index) => {
              const { title,assignedTo,status,remark } = item;
              return (
                <div className="position-relative" key={index}>
                  <span className="d-flex justify-content-end p-2 removeButton">
                    {taskDetailsArr.length === 1 ? null : (
                      <HighlightOffIcon
                        className="cursor"
                        color="primary"
                        fontSize="small"
                        onClick={() => handleClose(index)}
                      />
                    )}
                  </span>

                  <div className="card p-2 mt-2 mb-4">
                    <div className="d-flex my-2 align-items-center">
                      <InputField
                        labelName={"Task name"}
                        placeholder="Enter Task Name"
                        setInputText={(value) => onChangeTaskDetails(value, "title", index)}
                        value={title}
                      // onChangeInput={(value) => setMob(value)}
                      />
                    </div>
                    <div className="d-flex my-2 align-items-center">
                      <LabelField labelName={"Responsible"} />
                      <CustomDropdown
                        labelName={"Select Responsible Person"}
                        setInputText={(value) => onChangeTaskDetails(value?._id, "assignedTo", index)}
                        value={responsible} 
                        options={responsibleEmployees}
                        // {responsibleEmployees.map(employee => ({
                        //   label: employee.name, // Assuming the employee name field is 'name'
                          // value: employee._id
                        // }))}
                      />
                    </div>

                    <div className="d-flex my-2 align-items-center">
                      <LabelField labelName={"Status"} />
                      <CustomDropdown
                        labelName={"Select Status"}
                        setInputText={(value) => onChangeTaskDetails(value?._id, "status", index)}
                        value={status}
                        options={taskStatus}
                      />
                    </div>

                    <InputField
                      labelName={"Remark"}
                      placeholder="Enter Remark"
                      setInputText={(value) => onChangeTaskDetails(value, "remark", index)}
                      value={remark}
                    // onChangeInput={(value) => setMob(value)}
                    />
                  </div>
                </div>
        );
      })}
          <div className="col-auto d-flex justify-content-end mt-4">
              <AddMorebtn key="addBtn" handleClick={onPressAdd} />
            </div>
            <button
                  type="submit"
                  className="btn btn-primary"
                  // className={`btn mb-3 submitbtn ${
                  //   validations() ? "btn-secondary" : "btn-primary"
                  // }`}
                  // disabled={validations()}
                >
                  Submit
                </button>
          </form>
        </div>
      </div>
      
  );
}
export default MovementsOfMeeting;
 
 