export const DataEntryPanelSidebarData = [
  {
    title: "TOPIC",
    subNav: [
      {
        title: "CREATE",
        path: "createtopic",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletetopic",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUB-TOPIC",
    subNav: [
      {
        title: "CREATE",
        path: "createsubtopic",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesubtopic",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LECTURE",
    subNav: [
      {
        title: "CREATE",
        path: "createLecture",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteLecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addLiveVideoNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteLiveVideoNew",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PREMIER VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addPremierVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePremierVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "FREE USER VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addFreeUserVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteFreeUserVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE RECORDED VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addliverecordedvideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteliverecordedvideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BIND COURSES",
    subNav: [
      {
        title: "ADD COURSES",
        path: "addcourses",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecourses",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COPY RECORDED LECTURE",
    subNav: [
      {
        title: "COPY LECTURE",
        path: "copyrecordedlecture",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecopyrecordedlecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "NOTIFICATION",
    subNav: [
      {
        title: "CREATE",
        path: "createnotification",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletenotification",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN TEACHER",
    subNav: [
      {
        title: "CREATE",
        path: "createassignteacher",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignteacher",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM SET",
    subNav: [
      {
        title: "CREATE",
        path: "createexamset",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteexamset",
        cName: "sub-nav",
      },
      {
        title: "COPY EXAM SET",
        path: "copyexamset",
        cName: "sub-nav",
      },
      {
        title: "Question Bank Assign",
        path: "assignQuestionToExamSet",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "QUESTION",
    subNav: [
      {
        title: "CREATE",
        path: "createquestion",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletequestion",
        cName: "sub-nav",
      },
      {
        title: "SET QUESTION ORDER",
        path: "questionOrder",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "TEACHER SUPPORT",
    link: "teachersupport",
  },
  {
    title: "RAISE ISSUE",
    subNav: [
      {
        title: "CREATE",
        path: "createraiseissue",
        cName: "sub-nav",
      },
      {
        title: "ISSUE LIST",
        path: "editdeleteraiseissue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE CLASS SCHEDULE",
    link: "liveclassschedule",
  },
  {
    title: "TEACHER TASK PLAN",
    link: "teacherTaskPlan",
  },
  {
    title: "TEACHER TASK PLAN COMPLETE",
    link: "teacherTaskPlanComplete",
  },
  {
    title: "TEACHER TASK PLAN PENDING",
    link: "teacherTaskPlanPending",
  },
  {
    title: "TEACHER TASK PLAN NOT COMPLETED",
    link: "teacherTaskPlanNotCompleted",
  },
  {
    title: "SYLLABUS",
    link: "syllabusDashboard",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationDetails",
  },
  {
    title: "QUESTION BANK",
    subNav: [
      {
        title: "CREATE",
        path: "questionBankEditor",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteQuestionBank",
        cName: "sub-nav",
      },
      {
        title: "LECTURE ADD / EDIT",
        path: "replaceLecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "IMPORT QUESTIONS",
    link: "importQuestions",
  },
  {
    title: "COPY PACKAGE",
    subNav: [
      {
        title: "COPY PACKAGE",
        path: "copyPackage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editCopyPackage",
        cName: "sub-nav",
      },
    ],
  },
];
export const DataEntryPanelEmpSidebarData = [
  {
    title: "TOPIC",
    subNav: [
      {
        title: "CREATE",
        path: "createtopic",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletetopic",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUB-TOPIC",
    subNav: [
      {
        title: "CREATE",
        path: "createsubtopic",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesubtopic",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LECTURE",
    subNav: [
      {
        title: "CREATE",
        path: "createLecture",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteLecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addLiveVideoNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteLiveVideoNew",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PREMIER VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addPremierVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePremierVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "FREE USER VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addFreeUserVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteFreeUserVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE RECORDED VIDEO",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addliverecordedvideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteliverecordedvideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BIND COURSES",
    subNav: [
      {
        title: "ADD COURSES",
        path: "addcourses",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecourses",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COPY RECORDED LECTURE",
    subNav: [
      {
        title: "COPY LECTURE",
        path: "copyrecordedlecture",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecopyrecordedlecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "NOTIFICATION",
    subNav: [
      {
        title: "CREATE",
        path: "createnotification",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletenotification",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN TEACHER",
    subNav: [
      {
        title: "CREATE",
        path: "createassignteacher",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignteacher",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM SET",
    subNav: [
      {
        title: "CREATE",
        path: "createexamset",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteexamset",
        cName: "sub-nav",
      },
      {
        title: "COPY EXAM SET",
        path: "copyexamset",
        cName: "sub-nav",
      },
      {
        title: "Question Bank Assign",
        path: "assignQuestionToExamSet",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "QUESTION",
    subNav: [
      {
        title: "CREATE",
        path: "createquestion",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletequestion",
        cName: "sub-nav",
      },
      {
        title: "SET QUESTION ORDER",
        path: "questionOrder",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "TEACHER SUPPORT",
    link: "teachersupport",
  },
  {
    title: "RAISE ISSUE",
    subNav: [
      {
        title: "CREATE",
        path: "createraiseissue",
        cName: "sub-nav",
      },
      {
        title: "ISSUE LIST",
        path: "editdeleteraiseissue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LIVE CLASS SCHEDULE",
    link: "liveclassschedule",
  },
  {
    title: "TEACHER TASK PLAN",
    link: "teacherTaskPlan",
  },
  {
    title: "TEACHER TASK PLAN COMPLETE",
    link: "teacherTaskPlanComplete",
  },
  {
    title: "TEACHER TASK PLAN PENDING",
    link: "teacherTaskPlanPending",
  },
  {
    title: "TEACHER TASK PLAN NOT COMPLETED",
    link: "teacherTaskPlanNotCompleted",
  },
  {
    title: "SYLLABUS",
    link: "syllabusDashboard",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationDetails",
  },
  {
    title: "QUESTION BANK",
    subNav: [
      {
        title: "CREATE",
        path: "questionBankEditor",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteQuestionBank",
        cName: "sub-nav",
      },
      {
        title: "LECTURE ADD / EDIT",
        path: "replaceLecture",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "IMPORT QUESTIONS",
    link: "importQuestions",
  },
  {
    title: "COPY PACKAGE",
    subNav: [
      {
        title: "COPY PACKAGE",
        path: "copyPackage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editCopyPackage",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
