import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  SuperPanelPanelSidebarData,
  SuperPanelEmployeeSidebarData,
} from "../../Module/superAdminPanelSidebarData";
import { useSelector } from "react-redux";
const ActiveUser = lazy(() => import("../CXOPanel/activeUser"));

const ManageEmployee = React.lazy(() => import("./manageEmployee"));

const AddCompany = React.lazy(() => import("./addCompany"));
const EditDeleteCompany = React.lazy(() => import("./editDeleteCompany"));

const AddDepartment = React.lazy(() => import("./addDepartment"));
const EditDeleteDepartment = React.lazy(() => import("./editDeleteDepartment"));

const AddDesignation = React.lazy(() => import("./addDesignation"));
const EditDeleteDesignation = React.lazy(() =>
  import("./editDeleteDesignation")
);

const PayRoll = React.lazy(() => import("./payRoll"));
const AddSubDepartment = React.lazy(() => import("./addSubDepartment"));
const EditSubDepartment = React.lazy(() => import("./editSubDepartment"));
const PayRollCtc = lazy(() => import("./payRollCtc"));
const AddUpdatePolicy = lazy(() => import("./addUpdatePolicy"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const AddEditKraKpi = lazy(() => import("./addEditKraKpi"));

function SuperAdminDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2
            ? SuperPanelEmployeeSidebarData
            : SuperPanelPanelSidebarData
        }
      />

      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ManageEmployee />} />
              <Route path="manageEmployee" element={<ManageEmployee />} />
              <Route path="payRoll" element={<PayRoll />} />
              <Route path="addCompany" element={<AddCompany />} />
              <Route path="editDeleteCompany" element={<EditDeleteCompany />} />
              <Route path="addDepartment" element={<AddDepartment />} />
              <Route
                path="editDeleteDepartment"
                element={<EditDeleteDepartment />}
              />
              <Route path="addDesignation" element={<AddDesignation />} />
              <Route
                path="editDeleteDesignation"
                element={<EditDeleteDesignation />}
              />
              <Route path="addSubDepartment" element={<AddSubDepartment />} />
              <Route path="editSubDepartment" element={<EditSubDepartment />} />
              <Route path="payRollCtc" element={<PayRollCtc />} />
              <Route path="addUpdatePolicy" element={<AddUpdatePolicy />} />
              <Route
                path="movementsOfMeeting"
                element={<MovementsOfMeeting />}
              />
              <Route
                path="editMovementsOfMeeting"
                element={<EditMovementsOfMeeting />}
              />
              <Route path="addKraKpi" element={<AddEditKraKpi />} />
              <Route path="activeUser" element={<ActiveUser />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default SuperAdminDashboard;
