import "../../App.css";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import Sidebar from "../../Module/sidebar";
import { YoutubeSidebarData } from "../../Module/youtubeSidebarData";

const CreateTarget = lazy(() => import("./YoutubeTarget/createTargetYoutube"));
const TargetStatus = lazy(() => import("./YoutubeTarget/targetStatusYoutube"));

function YoutubeDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={YoutubeSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CreateTarget />} />
              <Route path="createTargetYoutube" element={<CreateTarget />} />
              <Route path="targetStatusYoutube" element={<TargetStatus />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}
export default YoutubeDashboard;
