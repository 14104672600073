import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import {
  SocialMediaManagerData,
  SocialMediaSideBarData,
} from "../../Module/SocialMediaPanel/socialMediaSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import SocialAds from "./socialAds";
import ResultCompilation from "../../Module/resultCompilation";
import { useSelector } from "react-redux";
import EditDeleteSocialMediaLink from "./editDeleteSocialMediaLink";

const LeadDashboard = React.lazy(() =>
  import("../CategoryPanel/leadDashboard")
);
const LeadCount = React.lazy(() => import("../CategoryPanel/leadCount"));
const LeadAnalysis = React.lazy(() => import("../CategoryPanel/leadAnalysis"));

const TargetStatus = React.lazy(() => import("../CategoryPanel/targetStatus"));
const CreateTarget = React.lazy(() => import("./createTargetSocialMedia"));
const SocialMediaLinks = React.lazy(() => import("./socialMediaLinks"));
const CreateDummy = lazy(() => import("./DummyAccount/createDummyAccount"));
const EditDummy = lazy(() => import("./DummyAccount/editDeleteDummyAccount"));
const CreateFacebookGroup = lazy(() =>
  import("./FacebookGroup/createFacebookGroup")
);
const EditFacebookGroup = lazy(() =>
  import("./FacebookGroup/editDeleteFacebookGroup")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));
const SocialMediaDashboard = () => {
  const { designation ,loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={
          designation === "YOUTUBE GROWTH MANAGER"
            ? SocialMediaManagerData
            : SocialMediaSideBarData
        }
      />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<SocialAds />} />
              <Route path="socialAds" element={<SocialAds />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="leadDashboard" element={<LeadDashboard />} />
              <Route path="LeadCount" element={<LeadCount />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route path="targetStatus" element={<TargetStatus />} />
              <Route path="createTarget" element={<CreateTarget />} />
              <Route path="socialMediaLinks" element={<SocialMediaLinks />} />
              <Route
                path="EditDeleteSocialMediaLink"
                element={<EditDeleteSocialMediaLink />}
              />
              <Route path="createDummyAccount" element={<CreateDummy />} />
              <Route path="editDummyAccount" element={<EditDummy />} />
              <Route
                path="createFacebookGroup"
                element={<CreateFacebookGroup />}
              />
              <Route
                path="editDeleteFacebookGroup"
                element={<EditFacebookGroup />}
              />
              <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
              <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default SocialMediaDashboard;
