export const SubRoleSidebarData = [
  {
    title: "MANAGE EMPLOYEE",
    link: "manageEmployee",
  },
  {
    title: "PAYROLL",
    link: "payRoll",
  },
  {
    title: "COMPANY",
    subNav: [
      {
        title: "ADD COMPANY",
        path: "addCompany",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DESIGNATION",
        path: "editDeleteCompany",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DEPARTMENT",
    subNav: [
      {
        title: "ADD DEPARTMENT",
        path: "addDepartment",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DEPARTMENT",
        path: "editDeleteDepartment",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUB DEPARTMENT",
    subNav: [
      {
        title: "ADD SUB DEPARTMENT",
        path: "addSubDepartment",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE SUB DEPARTMENT",
        path: "editSubDepartment",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "DESIGNATION",
    subNav: [
      {
        title: "ADD DESIGNATION",
        path: "addDesignation",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DESIGNATION",
        path: "editDeleteDesignation",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ACTIVE USER",
    link: "activeUser",
  },
  {
    title: "SAlARY ANALYSIS",
    link: "payRollCtc",
  },
];


export const SubRoleEmpSidebarData = [
  {
    title: "MANAGE EMPLOYEE",
    link: "manageEmployee",
  },
  {
    title: "PAYROLL",
    link: "payRoll",
  },
  {
    title: "COMPANY",
    subNav: [
      {
        title: "ADD COMPANY",
        path: "addCompany",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DESIGNATION",
        path: "editDeleteCompany",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DEPARTMENT",
    subNav: [
      {
        title: "ADD DEPARTMENT",
        path: "addDepartment",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DEPARTMENT",
        path: "editDeleteDepartment",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUB DEPARTMENT",
    subNav: [
      {
        title: "ADD SUB DEPARTMENT",
        path: "addSubDepartment",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE SUB DEPARTMENT",
        path: "editSubDepartment",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "DESIGNATION",
    subNav: [
      {
        title: "ADD DESIGNATION",
        path: "addDesignation",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE DESIGNATION",
        path: "editDeleteDesignation",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SAlARY ANALYSIS",
    link: "payRollCtc",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
